import { createReducer, on } from '@ngrx/store'
import { Action, ActionReducer } from '@ngrx/store/src/models'
import * as fromActions from './matches.actions'
import { MatchesState } from './matches.state'

export const matchesInitialState: MatchesState = {
  categories: null,
  matches: null,
  selectedCategory: null,
  loading: null,
  error: null,
};

export const matchesReducers: ActionReducer<MatchesState, Action> =
  createReducer<MatchesState>(
    matchesInitialState,
    on(
      fromActions.getMatchesAction,
      (state: MatchesState): MatchesState => ({
        ...state,
        loading: true,
        error: null,
        categories: null,
        matches: null,
        selectedCategory: null
      })
    ),
    on(
      fromActions.getCategoriesAction,
      (state: MatchesState, action): MatchesState => ({
        ...state,
        categories: action.categories
      })
    ),
    on(
      fromActions.getMatchesSuccessAction,
      (state: MatchesState, action): MatchesState => ({
        ...state,
        loading: false,
        error: null,
        matches: action.matches,
      })
    ),
    on(
      fromActions.getMatchesErrorAction,
      (state: MatchesState, action): MatchesState => ({
        ...state,
        loading: false,
        error: action.error,
      })
    ),
    on(
      fromActions.getSelectedMatchByCategoryAction,
      (state: MatchesState, action): MatchesState => ({
        ...state,
        selectedCategory: action.category
      })
    )
  );
