import { PositionsTableCategory } from '@app/models/server'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { PositionsTableState } from './positions-table.state'

export const selectPositionsTable = createFeatureSelector<PositionsTableState>('positionsTable');

export const selectGetPositionsTable = createSelector(
  selectPositionsTable,
  (state: PositionsTableState): PositionsTableCategory[] | null => state.positionsTable
);

export const selectGetPositionsTableLoading = createSelector(
  selectPositionsTable,
  (state: PositionsTableState): boolean | null => state.loading
);

export const selectGetSelectedPositionTable = createSelector(
  selectPositionsTable,
  (state: PositionsTableState): PositionsTableCategory | null => state.selectedPositionTable
);