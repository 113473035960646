import { createReducer, on } from '@ngrx/store'
import { Action, ActionReducer } from '@ngrx/store/src/models'
import * as fromActions from './positions-table.actions'
import { PositionsTableState } from "./positions-table.state"

export const positionsTableInitialState: PositionsTableState = {
  positionsTable: null,
  selectedPositionTable: null,
  loading: null,
  error: null
}

export const positionsTableReducers: ActionReducer<PositionsTableState, Action> =
  createReducer<PositionsTableState>(
    positionsTableInitialState,
    on(
      fromActions.getPositionsTableAction,
      (state: PositionsTableState): PositionsTableState => ({
        ...state,
        loading: true,
        error: null,
        positionsTable: null,
        selectedPositionTable: null
      })
    ),
    on(
      fromActions.getPositionsTableSuccessAction,
      (state: PositionsTableState, action): PositionsTableState => ({
        ...state,
        loading: false,
        error: null,
        positionsTable: action.positionsTable
      })
    ),
    on(
      fromActions.getPositionsTableErrorAction,
      (state: PositionsTableState, action): PositionsTableState => ({
        ...state,
        loading: false,
        error: action.error,
      })
    ),
    on(
      fromActions.getSelectedTablePositionAction,
      (state: PositionsTableState, action): PositionsTableState => ({
        ...state,
        selectedPositionTable: action.selectedPositionTable
      })
    ),
  );