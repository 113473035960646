import { inject } from '@angular/core'
import { Category, MatchesResponse } from '@app/models/server'
import { MatchService } from '@app/services'
import * as fromMatches from '@app/store/matches'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { catchError, delay, map, mergeMap, of, tap } from 'rxjs'

export const getMatches$ = createEffect(
  (
    store: Store = inject(Store),
    actions$ = inject(Actions),
    matchService: MatchService = inject(MatchService)
  ) => {
    return actions$.pipe(
      ofType(fromMatches.getMatchesAction),
      mergeMap(() => {
        return matchService.getMatches().pipe(
          delay(1000),
          tap((res: MatchesResponse): void => {
            const categories: string[] = res.datos.Categorias.map((category: Category): string => category.S_Categoria);

            store.dispatch(fromMatches.getCategoriesAction({ categories }));
          }),
          map((res: MatchesResponse) => {
            return fromMatches.getMatchesSuccessAction({
              matches: res.datos.Categorias,
            });
          }),
          catchError((error) => of(fromMatches.getMatchesErrorAction(error)))
        );
      })
    );
  },
  { functional: true }
);
