import { PositionsTableCategory } from '@app/models/server'
import { createAction, props } from '@ngrx/store'
import { PositionsTableTypes } from './positions-table.types'

export const getPositionsTableAction = createAction(PositionsTableTypes.GET_POSITIONS_TABLE);

export const getPositionsTableSuccessAction = createAction(
  PositionsTableTypes.GET_POSITIONS_TABLE_SUCCESS,
  props<{ positionsTable: PositionsTableCategory[] }>()
);

export const getPositionsTableErrorAction = createAction(
  PositionsTableTypes.GET_POSITIONS_TABLE_ERROR,
  props<{ error: string }>()
);

export const getSelectedTablePositionAction = createAction(
  PositionsTableTypes.GET_SELECTED_POSITIONS_TABLE_,
  props<{ selectedPositionTable: PositionsTableCategory }>()
);