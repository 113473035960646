import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { PositionsTableResponse, TeamsResponse } from '@app/models/server'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'
import { TeamUrl } from './team.urls'

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private _http: HttpClient;
  private _url: string;

  constructor() {
    this._http = inject(HttpClient);
    this._url = environment.url;
  }

  public getTeams(): Observable<TeamsResponse> {
    return this._http.get<TeamsResponse>(`${this._url}${TeamUrl.TEAM}`);
  }

  public getPositionsTable(): Observable<PositionsTableResponse> {
    return this._http.get<PositionsTableResponse>(`${this._url}${TeamUrl.TEAM}${TeamUrl.POSITIONS_TABLE}`);
  }
}
