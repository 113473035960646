import { Category } from '@app/models/server'
import { createAction, props } from '@ngrx/store'
import { MatchesTypes } from './matches.types'

export const getMatchesAction = createAction(MatchesTypes.GET_MATCHES);

export const getMatchesSuccessAction = createAction(
  MatchesTypes.GET_MATCHES_SUCCESS,
  props<{ matches: Category[] }>()
);

export const getMatchesErrorAction = createAction(
  MatchesTypes.GET_MATCHES_ERROR,
  props<{ error: string }>()
);

export const getSelectedMatchByCategoryAction = createAction(
  MatchesTypes.GET_SELECTED_MATCH_BY_CATEGORY,
  props<{ category: Category }>()
);

export const getCategoriesAction = createAction(
  MatchesTypes.GET_CATEGORIES,
  props<{ categories: string[] }>()
);