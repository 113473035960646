import { HostListener, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class UnSavedChangesService {
  private _hasUnsavedChanges: boolean;

  constructor() {
    this._hasUnsavedChanges = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  checkForUnsavedChanges(event: Event) {
    if (this._hasUnsavedChanges) {
      event.preventDefault();
      // event.returnValue = false;
      window.alert('Tienes cambios sin guardar');
      // return 'Tienes cambios sin guardar';
    }
    // return null;
  }

  public markAsChanged(): void {
    this._hasUnsavedChanges = true;
  }

  private _markAsSaved(): void {
    this._hasUnsavedChanges = false;
  }
}
