import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MatTableModule } from '@angular/material/table'
import { PositionsTableTeam } from '@app/models/server'

@Component({
  selector: 'app-positions',
  standalone: true,
  imports: [CommonModule, MatTableModule],
  template: `
    <table mat-table [dataSource]="positionsTable" class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>EQUIPO</th>
        <td mat-cell *matCellDef="let element">{{ element.S_Equipo }}</td>
      </ng-container>
      <ng-container matColumnDef="gamesPlayed">
        <th mat-header-cell *matHeaderCellDef>J</th>
        <td mat-cell *matCellDef="let element">{{ element.JJ }}</td>
      </ng-container>
      <ng-container matColumnDef="gamesWon">
        <th mat-header-cell *matHeaderCellDef>G</th>
        <td mat-cell *matCellDef="let element">{{ element.PG }}</td>
      </ng-container>
      <ng-container matColumnDef="gamesLost">
        <th mat-header-cell *matHeaderCellDef>P</th>
        <td mat-cell *matCellDef="let element">
          {{ element.JJ - element.PG }}
        </td>
      </ng-container>
      <ng-container matColumnDef="goalsFor">
        <th mat-header-cell *matHeaderCellDef>PF</th>
        <td mat-cell *matCellDef="let element">{{ element.GF }}</td>
      </ng-container>
      <ng-container matColumnDef="goalsAgainst">
        <th mat-header-cell *matHeaderCellDef>PC</th>
        <td mat-cell *matCellDef="let element">{{ element.GC }}</td>
      </ng-container>
      <ng-container matColumnDef="goalDifference">
        <th mat-header-cell *matHeaderCellDef>DIF</th>
        <td mat-cell *matCellDef="let element">
          {{ element.GF - element.GC }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  `,
  styleUrl: './positions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionsComponent {
  @Input() public positionsTable!: PositionsTableTeam[];

  public displayedColumns: string[] = [
    'name',
    'gamesPlayed',
    'gamesWon',
    'gamesLost',
    'goalsFor',
    'goalsAgainst',
    'goalDifference',
  ];
}
