import { Pipe, type PipeTransform } from '@angular/core'
// import { Observable, interval, map } from 'rxjs'

@Pipe({
  name: 'appNextMatchStatus',
  standalone: true,
})
export class NextMatchStatusPipe implements PipeTransform {
  transform(matchDate: string | undefined): boolean {
    const currentDate = new Date();
    const matchDateTransform = new Date(matchDate as string);
    // const temp = interval(1000).pipe(map((): boolean => currentDate > matchDateTransform))

    return currentDate > matchDateTransform;
  }
}
