import { Pipe, type PipeTransform } from '@angular/core'

@Pipe({
  name: 'appFirstLetter',
  standalone: true,
})
export class FirstLetterPipe implements PipeTransform {
  transform(value: string | undefined): string {
    return !value ? '' : value.charAt(0).toUpperCase();
  }
}
