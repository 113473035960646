import { inject } from '@angular/core'
import { PositionsTableResponse } from '@app/models/server'
import { TeamService } from '@app/services'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, delay, map, mergeMap, of } from 'rxjs'
import * as fromActions from './positions-table.actions'

export const getPositionsTable$ = createEffect(
  (
    actions$ = inject(Actions),
    teamService: TeamService = inject(TeamService)
  ) => {
    return actions$.pipe(
      ofType(fromActions.getPositionsTableAction),
      mergeMap(() => {
        return teamService.getPositionsTable().pipe(
          delay(1000),
          map((res: PositionsTableResponse) =>
            fromActions.getPositionsTableSuccessAction({ positionsTable: res.datos })
          ),
          catchError((error) => of(fromActions.getPositionsTableErrorAction(error)))
        );
      })
    );
  },
  { functional: true }
);
