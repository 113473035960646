import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import {
  MatchRequest,
  MatchUpdateResponse,
  MatchesResponse,
} from '@app/models/server'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'
import { MatchUrl } from './match.urls'

@Injectable({
  providedIn: 'root',
})
export class MatchService {
  private _http: HttpClient;
  private _url: string;

  constructor() {
    this._http = inject(HttpClient);
    this._url = environment.url;
  }

  public getMatches(): Observable<MatchesResponse> {
    return this._http.get<MatchesResponse>(`${this._url}${MatchUrl.MATCH}`);
  }

  public updateMatch(
    id: number,
    body: MatchRequest
  ): Observable<MatchUpdateResponse> {
    return this._http.put<MatchUpdateResponse>(
      `${this._url}${MatchUrl.MATCH}/${id}`,
      body
    );
  }
}
