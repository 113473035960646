import { Category } from '@app/models/server'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { MatchesState } from './matches.state'

export const selectMatches = createFeatureSelector<MatchesState>('matches');

export const selectGetMatches = createSelector(
  selectMatches,
  (state: MatchesState): Category[] | null => state.matches
);

export const selectGetMatchesLoading = createSelector(
  selectMatches,
  (state: MatchesState): boolean | null => state.loading
);

export const selectGetSelectedCategory = createSelector(
  selectMatches,
  (state: MatchesState): Category | null => state.selectedCategory
);

export const selectGetCategories = createSelector(
  selectMatches,
  (state: MatchesState): string[] | null => state.categories
);
